import { useNavigate, useParams } from "react-router-dom";
import ContainerContent from "../../components/layouts/ContainerContent";
import api from "../../api";
import { CloudfrontUrl, FunctionUploadFileAws } from "../../utils/useFunctions";
import { Controllers, FunctionGetYouTubeId, Page } from "radium-omnibus";
import { valueSocial } from "../../utils/constant";
import {
  ON_CANCEL_PORT,
  ON_SUBMIT_PORT,
  STYLE_BORDER_CIRCLE,
  STYLE_FLEX_TITLE,
  STYLE_ICON,
  STYLE_INPUT_REVIEW_SOCIAL,
  STYLE_TITLE,
} from "../../lib/style";
import useToastify from "../../utils/useToastify";

import FieldUploadFile from "../../components/fields/FieldUploadFile";
import FieldText from "../../components/fields/FieldText";

import Iframe from "react-iframe";
import ComponentRadio from "./render/ComponentRadio";
import React, { useEffect } from "react";
import { ICON_PORTFOLIO } from "../../lib/icons";

const PortfolioDetail = () => {
  const router = useNavigate();
  const params = useParams();
  const { notifySuccess } = useToastify();

  return (
    <Page
      api={api}
      path="/portfolios"
      mode="detail"
      params={params}
      navigate={navigator}
      renderBody={({ data, loading }) => {
        const onSubmit = async (form) => {
          const image_key = await FunctionUploadFileAws(form?.received?.file);
          const radio = form?.received?.radio;
          const link = form?.received?.link;
          try {
            if (data?.portfolio?._id) {
              await api.put(`/portfolios/${data?.portfolio?._id}`, {
                ...form,
                image_key,
                received: image_key,
                selected_type: form?.selected_type,
                radio,
                link,
              });
              router("/Portfolio");
              notifySuccess();
            } else {
              await api.post(`/portfolios`, {
                ...form,
                image_key,
                received: image_key,
                selected_type: form?.selected_type,
                radio,
                link,
              });
              router("/Portfolio");
              notifySuccess();
            }
          } catch (error) {
            console.error("ERROR TO SUBMIT PORTFOLIO : ", error);
          }
        };
        if (loading) {
          return <div>Loading...</div>;
        }
        return (
          <ContainerContent>
            {/* <div
              className="text-2xl flex gap-1
             "
            >
              <ICON_PORTFOLIO className={"w-7 "} />
              <div> Create Portfolio </div>
            </div> */}
            <div className={STYLE_FLEX_TITLE}>
              <div className={STYLE_BORDER_CIRCLE}>
                <ICON_PORTFOLIO className={STYLE_ICON} />
              </div>
              <div className="text-glowon-darkgray  text-xl">
                {" "}
                Create Portfolio
              </div>
            </div>
            <div className="mt-5 ">
              <Controllers
                loadData={{
                  ...data?.portfolio,
                  received: {
                    file: data?.portfolio?.image_key,
                    radio: data?.portfolio?.radio,
                    link: data?.portfolio?.link,
                  },
                }}
                onSubmit={onSubmit}
                classNameSubmit={ON_SUBMIT_PORT}
                labelSubmit="SAVE"
                labelCancel="CANCEL"
                onCancel={() => {
                  router("/Portfolio");
                }}
                classNameCancel={ON_CANCEL_PORT}
                classNameWrapper="flex gap-3 items-center justify-center"
                fields={[
                  {
                    type: "select",
                    name: "type",
                    label: "Social Media",
                    classNameOption: " my-1 p-2 hover:bg-gray-200",
                    classNameOptions: "bg-gray-50  border border-gray-200",
                    classNameValue:
                      "border border-gray-500  rounded-md p-2 bg-gray-100 shadow-xl cursor-pointer",
                    classNameInput: "w-1/2",

                    options: valueSocial,
                    emitter: ["received", "selected_type"],
                    required: " true ",
                    renderError: ({ error }) => {
                      return "";
                    },
                  },
                  {
                    type: "customs",
                    name: "received",
                    emitter: "type",
                    recipient: true,
                    required: " true ",
                    renderError: ({ error }) => {
                      return "";
                    },
                    fieldCustoms: ({
                      value,
                      setValue,
                      received,
                      name,
                      isEdit = false,
                    }) => {
                      const clearForm = () => {
                        setValue((prev) => ({
                          ...prev,
                          link: "",
                          file: "",
                          radio: "",
                        }));
                      };

                      if (received?.value === "facebook") {
                        return (
                          <div>
                            <ComponentRadio
                              isLoad={data?.portfolio?._id ? true : false}
                              value={value}
                              setValue={setValue}
                              option={["mini_review", "buzz_shout_out"]}
                            />

                            <FieldUploadFile
                              isLoad={data?.portfolio?._id ? true : false}
                              value={value?.file}
                              setValue={(file) => {
                                setValue({
                                  ...value,
                                  file: file,
                                });
                              }}
                              name={name}
                              getPathUrl={CloudfrontUrl}
                            />
                          </div>
                        );
                      } else if (received?.value === "youtube") {
                        return (
                          <div>
                            <ComponentRadio
                              isLoad={data?.portfolio?._id ? true : false}
                              value={value}
                              setValue={setValue}
                              option={["mini_review", "buzz_shout_out"]}
                            />
                            {value?.radio === "buzz_shout_out" ? (
                              <FieldText
                                className={STYLE_INPUT_REVIEW_SOCIAL}
                                value={value?.link}
                                onChange={(e) => {
                                  setValue({
                                    ...value,
                                    link: e.target.value,
                                  });
                                }}
                                name={name}
                                placeholder="เช่น https://youtu.be/KDKva-s_khY"
                              />
                            ) : (
                              <FieldUploadFile
                                isLoad={data?.portfolio?._id ? true : false}
                                value={value?.file}
                                setValue={(file) => {
                                  setValue({
                                    ...value,
                                    file: file,
                                  });
                                }}
                                name={name}
                                getPathUrl={CloudfrontUrl}
                              />
                            )}
                            <div className="w-auto h-auto">
                              {value?.link &&
                                value?.radio === "buzz_shout_out" && (
                                  <div className="h-96 w-96">
                                    <Iframe
                                      url={`http://www.youtube.com/embed/${FunctionGetYouTubeId(
                                        value.link
                                      )}`}
                                      width="100%"
                                      height="100%"
                                      display="initial"
                                      position="relative"
                                      className="shadow-xl"
                                    />
                                  </div>
                                )}
                            </div>
                          </div>
                        );
                        //
                      } else if (
                        received?.value === "twitter" ||
                        received?.value === "user_review" ||
                        received?.value === "instagram" ||
                        received?.value === "tiktok" ||
                        received?.value === "event" ||
                        received?.value === "banner"
                      ) {
                        return (
                          <FieldUploadFile
                            isLoad={data?.portfolio?._id ? true : false}
                            received={received}
                            clearForm={clearForm}
                            value={value?.file}
                            setValue={(file) => {
                              setValue({
                                ...value,
                                file: file,
                              });
                            }}
                            name={name}
                            getPathUrl={CloudfrontUrl}
                          />
                        );
                      } else {
                        return;
                      }
                    },
                  },
                  {
                    label: "Title",
                    name: "title",
                    type: "text",
                    required: " true ",
                    classNameLabel: "mt-2",
                    classNameInput: STYLE_INPUT_REVIEW_SOCIAL,

                    renderError: ({ error }) => {
                      return "";
                    },
                  },
                  // {
                  //   label: "Description",
                  //   name: "description",
                  //   type: "text",
                  //   // required: " true ",
                  //   classNameLabel: "mt-2",
                  //   classNameInput:
                  //     "border  my-2 py-5 w-2/3 rounded focus:outline-none px-4 p-2 text-gray-700 font-light text-xs resize-none",
                  //   renderError: ({ error }) => {
                  //     return "";
                  //   },
                  // },
                ]}
              />
            </div>
          </ContainerContent>
        );
      }}
    />
  );
};
export default PortfolioDetail;
