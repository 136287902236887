import { render } from "@testing-library/react";
import { label } from "aws-amplify";
import { ICON_TRASH } from "../lib/icons";
import { STYLE_DELTE_BUTTON } from "../lib/style";

export const controllerFields = (
  render = () => <></>,
  label = "",
  name = "",
  type = "",
  getPathUrl,
  classNameLabel = "text-lg",
  classNameInput = "",
  required = false
  // renderError = ({ error }) => {
  //   return "";
  // }
) => {
  return {
    render: render,
    label,
    name,
    type,
    getPathUrl,
    classNameLabel,
    classNameInput,
    required,
    // renderError,
  };
};

export const DeleteButton = ({ onClick }) => {
  return (
    <div onClick={onClick} className={STYLE_DELTE_BUTTON}>
      <ICON_TRASH className="w-4" />
      Delete
    </div>
  );
};
