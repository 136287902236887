import { set } from "lodash";
import React from "react";

const ComponentRadio = ({ value, setValue, option, isLoad }) => {
  React.useEffect(() => {
    if (isLoad) return;
    setValue((prev) => {
      let temp = { ...prev };
      temp["radio"] = "mini_review";
      return temp;
    });
  }, [setValue]);

  React.useEffect(() => {
    if (isLoad) return;
    if (value?.radio !== "buzz_shout_out") {
      setValue((prev) => {
        let temp = { ...prev };
        temp["link"] = "";
        return temp;
      });
    } else if (value?.radio !== "mini_review") {
      setValue((prev) => {
        let temp = { ...prev };
        temp["file"] = "";
        return temp;
      });
    } else {
      return;
    }
  }, [value?.radio]);

  return (
    <div className="flex gap-10 my-5">
      {option?.map((item, index) => {
        return (
          <div key={index} className="flex text-coolGray-700">
            <input
              type="radio"
              checked={value?.radio === item}
              onChange={() => {
                setValue((prev) => {
                  let temp = { ...prev };
                  temp["radio"] = item;
                  return temp;
                });
              }}
              className={`w-5 h-5 border border-gray-50 opacity-90`}
            />
            <div className="my-auto ml-1 text-sm">{item}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ComponentRadio;
