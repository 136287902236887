import axios from "axios";
// import { Auth } from "aws-amplify";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "https://icw5iqcel1.execute-api.ap-southeast-1.amazonaws.com/dev"
      : "https://icw5iqcel1.execute-api.ap-southeast-1.amazonaws.com/dev",

  //   headers: {
  //     ["X-Api-Key"]: "DASHBOARD",
  //   },
});

// export const getJwtBearer = async () => {
//   const {
//     accessToken: { jwtToken },
//   } = await Auth.currentSession();
//   return `Bearer ${jwtToken}`;
// };

export default instance;

// baseURL: "https://we4wtu9vub.execute-api.ap-southeast-1.amazonaws.com/dev",
