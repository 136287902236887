import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Sidebar from "./components/layouts/Sidebar";

import HomeMain from "./pages/home/HomeMain";
import BlogMain from "./pages/blog/BlogMain";
import PortfolioMain from "./pages/portfolio/Portfolio";
import ContactFormMain from "./pages/contactform/ContactFormMain";
import BlogDetail from "./pages/blog/BlogDetail";
import PortfolioDetail from "./pages/portfolio/PortfolioDetail";
import ControllerMain from "./pages/controller/ControllerMain";
import FormReviewer from "./pages/contactform/FormReviewer";
import FormMarketer from "./pages/contactform/FormMarketer";
import Amplify from "aws-amplify";
import RenderService from "./pages/controller/Controller.Components/RenderService";
import BannerMain from "./pages/banner/BannerMain";
import BannerDetail from "./pages/banner/BannerDetail";
import CelesToast from "./utils/CelesToast";

//CSS
import "react-toastify/dist/ReactToastify.css";
import LoginMain from "./pages/login/LoginMain";
import ProtecedRoute from "./components/protectroute/RouteProtector";
import ContainerRouteLocation from "./components/layouts/ContainerRouteLocation";

// export default awsmobile;
Amplify.configure({
  Auth: {
    identityPoolId: "ap-southeast-1:dfebe9a6-584e-4330-9653-2692a9c90a6d",
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_MhP5aPwFv",
    userPoolWebClientId: "59tb8scakrtqu3r9gg563rrpul",
  },
  Storage: {
    bucket: "bucketglowon152224-dev",
    region: "ap-southeast-1",
  },
});

function App() {
  // const isLogin = location.pathname === "/login";
  console.warn = function filterWarnings() {};
  console.error = function filterErrors() {};

  const pathname = window.location.pathname;

  const isLogin = pathname === "/login";

  const [showSidebar, setShowSidebar] = useState(true);

  const routes = [
    {
      title: "Login",
      path: "/login",
      element: <LoginMain />,
      show: false,
    },
    {
      title: "Home",
      path: "/",
      element: (
        <ProtecedRoute>
          {" "}
          <HomeMain />{" "}
        </ProtecedRoute>
      ),
      show: true,
    },
    {
      title: "Banner",
      path: "/banner",
      element: (
        <ProtecedRoute>
          {" "}
          <BannerMain />
        </ProtecedRoute>
      ),
      show: true,
    },

    {
      title: "BannerDetail",
      path: "/Banner/New",
      element: (
        <ProtecedRoute>
          {" "}
          <BannerDetail />{" "}
        </ProtecedRoute>
      ),
      show: false,
      exac: true,
    },
    {
      title: "Portfolio",
      path: "/Portfolio",
      element: (
        <ProtecedRoute>
          {" "}
          <PortfolioMain />
        </ProtecedRoute>
      ),
      show: true,
    },

    {
      title: "PortfolioDetail",
      path: "/Portfolio/:id",
      element: (
        <ProtecedRoute>
          {" "}
          <PortfolioDetail />{" "}
        </ProtecedRoute>
      ),
      show: false,
    },
    {
      title: "Blog",
      path: "/Blog",
      element: (
        <ProtecedRoute>
          {" "}
          <BlogMain />
        </ProtecedRoute>
      ),
      show: true,
    },
    {
      title: "BlogDetail",
      path: "/Blog/:id",
      element: (
        <ProtecedRoute>
          {" "}
          <BlogDetail />{" "}
        </ProtecedRoute>
      ), //edit
      show: false,
    },
    {
      title: "BlogDetail",
      path: "/Blog/New",
      element: (
        <ProtecedRoute>
          {" "}
          <BlogDetail />
        </ProtecedRoute>
      ), //new
      show: false,
      exac: true,
    },
    {
      title: "Contact Form",
      path: "/ContactForm",
      element: (
        <ProtecedRoute>
          <ContactFormMain />{" "}
        </ProtecedRoute>
      ),
      show: true,
    },
    {
      title: "Controllers",
      path: "/Controllers",
      element: (
        <ProtecedRoute>
          {" "}
          <ControllerMain />
        </ProtecedRoute>
      ),
      show: true,
    },

    {
      title: "FormReviewer",
      path: "/ContactForm/FormReviewer",
      element: (
        <ProtecedRoute>
          <FormReviewer />{" "}
        </ProtecedRoute>
      ),
      show: false,
    },
    {
      title: "FormMarketer",
      path: "/ContactForm/FormMarketer",
      element: (
        <ProtecedRoute>
          {" "}
          <FormMarketer />
        </ProtecedRoute>
      ),
      show: false,
    },
  ];

  return (
    <>
      <CelesToast />
      <Router>
        <React.Fragment>
          <Sidebar
            routes={routes}
            setShowSidebar={setShowSidebar}
            showSidebar={showSidebar}
          />
        </React.Fragment>

        {/* <div
          className={
            "relative font-Kanit " + (showSidebar && !isLogin ? "md:ml-64" : "")
          }
        > */}
        <ContainerRouteLocation showSidebar={showSidebar}>
          <Routes>
            {routes?.map((row, index) => {
              return (
                <Route
                  key={index}
                  path={row?.path}
                  caseSensitive={row?.caseSensitive || false}
                  element={row?.element}
                  exac={row?.exac}
                />
              );
            })}
          </Routes>
        </ContainerRouteLocation>
        {/* </div> */}
      </Router>
    </>
  );
}

export default App;
