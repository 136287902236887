import { useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import { useEffect, useState } from "react";
import { CloudfrontUrl, FunctionUploadFileAws } from "../../utils/useFunctions";
import ContainerContent from "../../components/layouts/ContainerContent";
import { Controllers, Page } from "radium-omnibus";
import FieldWysiwyg from "../../components/fields/FieldWysiwyg";
import useToastify from "../../utils/useToastify";
import {
  STYLE_ADD_BUTTON,
  STYLE_BLOG_INPUT,
  STYLE_BORDER_CIRCLE,
  STYLE_CANCEL_BUTTON,
  STYLE_HEAD_EDIT,
  STYLE_ICON,
  STYLE_INPUT,
  STYLE_LABEL,
  STYLE_SUBMIT_BUTTON,
} from "../../lib/style";
import { STYLE_BLOGDETAIL_INPUT_IMAGE } from "../../lib/style";
import { ICON_BLOG } from "../../lib/icons";

// let api = null;

const BlogDetail = () => {
  const router = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [thisId, setThisId] = useState("");
  const { notifySuccess, notifyError } = useToastify();

  useEffect(() => {
    if (params.id) {
      const thisPath = params.id;
      setThisId(thisPath);
    }
  }, [params]);

  useEffect(() => {
    if (thisId) {
      LoadData();
    } else {
      return;
    }
  }, [thisId]);

  const LoadData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/blogs/${thisId}`);
      setData(data?.blog);
    } catch (error) {
      console.error("ERROR TO LOAD BLOG : ", error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (form) => {
    try {
      if (thisId) {
        //edit
        let image_key = null;
        if (form?.image_key) {
          image_key = await FunctionUploadFileAws(form?.image_key);
        }
        await api.put(`/blogs/${thisId}`, { ...form, image_key });
        router("/blog");
        notifySuccess();
      } else {
        let image_key = null;
        if (form?.image_key) {
          image_key = await FunctionUploadFileAws(form?.image_key);
        }
        await api.post("/blogs", { ...form, image_key });
        router("/blog");
        notifySuccess();
      }
    } catch (error) {
      console.error("ERROR TO ADD NEW BLOG : ", error);
    }
    console.log("form : ", form);
  };

  // if (loading) return <RenderLoading />;

  return (
    <Page
      api={api}
      path="/blogs"
      mode="detail"
      params={params}
      navigate={navigator}
      renderBody={({ data, loading }) => {
        if (loading) {
          return <div>Loading...</div>;
        }
        return (
          <ContainerContent>
            <div className={STYLE_HEAD_EDIT}>
              <div className={STYLE_BORDER_CIRCLE}>
                <ICON_BLOG className={STYLE_ICON} />
              </div>
              Create Blog{" "}
            </div>
            <div className="my-10 ">
              <Controllers
                loadData={data.blog}
                onSubmit={onSubmit}
                classNameSubmit={STYLE_SUBMIT_BUTTON}
                onCancel={() => {
                  router("/blog");
                }}
                classNameCancel={STYLE_CANCEL_BUTTON}
                //
                classNameWrapper="flex gap-3 items-center justify-center"
                fields={[
                  {
                    label: "Title",
                    name: "title",
                    type: "text",
                    required: true,
                    renderError: ({ error }) => {
                      return "";
                    },
                    classNameInput: STYLE_INPUT,
                    classNameLabel: STYLE_LABEL,
                  },
                  {
                    label: "Description",
                    name: "description",
                    type: "text",
                    required: true,
                    renderError: ({ error }) => {
                      return "";
                    },
                    classNameInput: STYLE_INPUT,
                    classNameLabel: STYLE_LABEL,
                  },
                  {
                    label: "กรุณาเลือกรูปภาพ ขนาดความกว้างสูงสุดคือ 500x500 px",
                    name: "image_key",
                    type: "file",
                    classNameLabel: STYLE_LABEL,
                    getPathUrl: CloudfrontUrl,
                    required: true,
                    renderError: ({ error }) => {
                      return "";
                    },
                    classNameInput: STYLE_ADD_BUTTON,
                  },
                  {
                    type: "customs",
                    name: "content",
                    fieldCustoms: ({ setValue, value }) => {
                      return (
                        <div className="mt-4">
                          <FieldWysiwyg value={value} setValue={setValue} />
                        </div>
                      );
                    },
                  },
                ]}
              />
            </div>
          </ContainerContent>
        );
      }}
    />
  );
};

export default BlogDetail;
