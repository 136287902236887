//TITLE - CONTROLLER//
export const STYLE_TITLE =
  "text-glowon-darkgray font-CormorantGaramond uppercase text-xl font-bold";
export const STYLE_HEAD_EDIT =
  "text-2xl text-glowon-gray font-normal flex gap-2 items-center";
export const STYLE_LABEL = "text-glowon-textgray font-light mb-2";
export const STYLE_INPUT =
  "border border-glowon-yellowbutton w-full h-8 rounded-md mb-4";

//BUTTON//
export const STYLE_DELTE_BUTTON =
  "cursor-pointer mb-10 flex justify-center gap-1 items-center font-light text-sm w-24 text-red-700 hover:underline hover:underline-offset-2 hover:text-red-500 duration-200 border border-red-700 hover:border-red-500 rounded-full p-1";
export const STYLE_SUBMIT_BUTTON =
  "mt-4 bg-glowon-yellowbutton text-glowon-green hover:bg-glowon-yellow p-2 rounded-lg w-32 duration-300";
export const STYLE_CANCEL_BUTTON =
  "mt-4 bg-glowon-green text-white w-32 p-2 rounded-lg hover:bg-glowon-lightgreen duration-300";
export const STYLE_ADD_BUTTON =
  "flex justify-center gap-1 items-center bg-glowon-yellowbutton hover:bg-glowon-green hover:text-white text-glowon-green p-2 rounded-full text-sm w-32 duration-300 font-light ";
export const ACTIVE_STYLE =
  " font-normal w-32 bg-glowon-green text-white p-2 rounded-full text-center ";
export const INACTIVE_STYLE =
  "text-glowon-textgray font-light border border-glowon-green rounded-full p-2 w-32 text-center";
export const STYLE_UPLOAD_CLIENT =
  "bg-glowon-lightgray font-light text-glowon-gray cursor-pointer";
export const STYLE_CONFIRM_BUTTON_CLIENT =
  "bg-glowon-yellowbutton text-white hover:bg-glowon-green cursor-pointer text-center font-light rounded-full p-1 mt-2 duration-300";

//HOME//
export const STYLE_HEAD =
  "font-CormorantGaramond text-4xl uppercase font-bold text-glowon-gray text-center";
export const STYLE_BORDER_UNDERLINE =
  "border-b border-glowon-yellow w-44 mx-auto mt-2";
export const STYLE_ICON = "w-7 m-auto my-1 ";
export const STYLE_PARENT_GRID = "grid  gap-4";
export const STYLE_BODY_HOME = "px-12 text-sm text-glowon-textgray font-light";
export const STYLE_BORDER_CIRCLE =
  "border border-glowon-darkgray rounded-full w-10 h-10";
export const STYLE_FLEX_TITLE = "flex gap-2 items-center mt-6";

//BANNER//
export const STYLE_LABEL_BANNER =
  "font-light text-glowon-textgray my-2 text-sm";
export const STYLE_BANNER_INPUT =
  "flex items-center justify-center  p-2 rounded-full cursor-pointer  bg-glowon-yellowbutton hover:bg-glowon-green hover:text-white text-glowon-green w-40 my-5 font-light text-sm hover:font-normal duration-300 ";

//PORTFOLIO//
export const STYLE_HEAD_PORTFOLIO =
  "text-2xl  mt-10 my-5 text-glowon-darkgray font-normal flex gap-2";
export const STYLE_GRID_PORTFOLIO = "grid grid-cols-3 gap-6 mb-10";

//MARKETER//
export const STYLE_TABLE_MARKET =
  "grid grid-cols-12 w-full p-2 items-center bg-glowon-bgtextstory border border-glowon-green hover:bg-glowon-bgstorytype hover:shadow-md hover:shadow-glowon-bgtextstory duration-300 my-4 rounded-lg font-light text-glowon-gray text-center ";
export const STYLE_HEAD_TABLE_MARKET =
  "grid grid-cols-12 items-center text-xl text-glowon-green text-center";

//REVIEWER//
export const STYLE_ICON_REVIEW =
  "grid grid-cols-3 border mb-3 rounded-md   hover:bg-orange-300 duration-300 hover:text-white cursor-pointer";
export const STYLE_FORMREVIEWER_TEXT =
  " text-glowon-bggreenpopup flex gap-1 items-start";
export const STYLE_FORMREVIEWER_FONT =
  " font-light gap-line-clamp-2 text-glowon-darkgray";
export const STYLE_CARD_REVIEW =
  "bg-glowon-bgstorytype p-4 rounded-2xl  hover:shadow-lg hover:shadow-glowon-bgtextstory duration-300 border border-glowon-bggreenpopup text-sm";
export const STYLE_CARD_REVIEW_SOCIAL =
  "bg-white border border-glowon-bgtextstory rounded-lg p-2 my-4";
export const STYLE_PARENT_CARD_REVIEW_SOCAIL = "grid grid-cols-5";
export const STYLE_INPUT_REVIEW_SOCIAL =
  "w-full border border-glowon-yellowbutton p-1 my-2 rounded-md";
export const STYLE_TEXT_INPUT_REVIEW_SOCIAL =
  "w-full h-4 line-clamp-1 break-all text-xs px-1";

export const ADD_BUTTON_BLOG =
  "mt-3 w-full inline-flex justify-center rounded-md border   shadow-lg px-4 py-2  text-base font-medium text-white bg-orange-400 hover:text-white  duration-300 transform hover:scale-110  focus:outline-none   sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm";

export const ADD_BUTTON_PORTFOLIO =
  "mt-3 w-full inline-flex justify-center rounded-md border   shadow-lg px-4 py-2  text-base font-medium text-white bg-orange-400 hover:text-white  duration-300 transform hover:scale-110  focus:outline-none   sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm";

export const ON_SUBMIT_PORT =
  "w-28 text-center h-10 rounded-md text-black-500 bg-lime-100 hover:bg-green-300 font-bold py-2 px-4 duration-300 transform hover:scale-105";

export const ON_CANCEL_PORT =
  "w-28  text-center h-10 rounded-md text-black-500 bg-red-100 hover:bg-red-300 font-bold py-2 px-4 duration-300 transform hover:scale-105";

export const STYLE_CARDS_BUTTON =
  "text-prompt font-medium flex justify-center items-center duration-300 transform hover:scale-110  focus:outline-none border border-orange-300 w-36 h-16 rounded-md hover:bg-orange-300 hover:text-white cursor-pointer";

export const STYLE_BUTTON_HOME =
  "text-prompt font-medium flex justify-center items-center duration-300 transform hover:scale-110  focus:outline-none border border-orange-300 w-full h-32 rounded-md hover:bg-orange-300 hover:text-white cursor-pointer";

export const STYLE_CARDS_PORTFOLIO =
  "bg-white shadow-md h-full p-2 rounded-lg cursor-pointer  hover:shadow-stone-300 hover:shadow-xl duration-300";

export const STYLE_CARDS_BLOG =
  "bg-white hover:scale-105 shadow-md  h-full p-2  rounded-lg  cursor-pointer flex flex-col  flex-grow grid-cols-1  m-2   hover:shadow-stone-300 hover:shadow-xl duration-300  text-left ";

export const STYLE_BLOG_INPUT =
  "border  my-2 h-10 w-2/3 rounded focus:outline-none px-4 p-2 text-gray-700 font-light text-xs resize-none";

export const STYLE_BLOGDETAIL_INPUT_IMAGE =
  "border my-2 h-10 mb-4 w-28 text-center rounded focus:outline-none px-4 p-3  font-light text-xs resize-none bg-gray-100 hover:bg-gray-200 cursor-pointer  ";

export const STYLE_BLOGDETAIL_ONSUBMIT =
  "w-28 text-center h-10 rounded-md text-black-500 bg-lime-100 hover:bg-lime-300 font-bold py-2 px-4 duration-300 transform hover:scale-105";
