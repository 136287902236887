import { Storage, toast } from "aws-amplify";

export const CloudfrontUrl = "https://d27knvykw9eckd.cloudfront.net/public/";
export const BunketUrl =
  "https://bucketglowon152224-dev.s3.ap-southeast-1.amazonaws.com/public/";

export const FunctionGetPathUrl = (key) => {
  if (key) {
    return `${CloudfrontUrl}${key}`;
  } else {
    return;
  }
};

export const FunctionGetImageFromS3 = (key) => {
  if (key) {
    return `${CloudfrontUrl}${key}`;
  } else {
    return;
  }
};
export const FunctionAddUrlToFile = (file) => {
  return URL.createObjectURL(file);
};

export const FunctionUploadFileAws = async (file) => {
  if (file?.size) {
    const keyUpload = `${new Date().getTime()}_${file?.name}`;
    await Storage.put(keyUpload, file);
    return keyUpload;
  } else {
    return file;
  }
};

export const FunctionDeletePath = (input) => {
  if (input) {
    const new_path = String(input).replace(CloudfrontUrl, "");
    return new_path;
  } else {
    return;
  }
};

export const successDeleteAlert = () => {
  toast.success("ลบข้อมูลสำเร็จ", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const onImageUploadBefore = (files, info, uploadHandler) => {
  if (!files && files === null && files === undefined && files < 0) return;
  (async () => {
    const responseStorage = await FunctionUploadFileAws(files[0]);
    const responseUpload = {
      result: [
        {
          url: `${CloudfrontUrl}${responseStorage}`,
          name: files[0].name,
          size: files[0].size,
        },
      ],
    };
    await uploadHandler(responseUpload);
  })();
  uploadHandler();
};
