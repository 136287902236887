import { Controllers, FunctionGetYouTubeId, Page } from "radium-omnibus";
import React, { useState } from "react";
import ContainerContent from "../../../components/layouts/ContainerContent";

import api from "../../../api";
import {
  CloudfrontUrl,
  FunctionUploadFileAws,
} from "../../../utils/useFunctions";
import FieldUploadFile from "../../../components/fields/FieldUploadFile";
import Iframe from "react-iframe";
import FieldText from "../../../components/fields/FieldText";
import useToastify from "../../../utils/useToastify";
import {
  STYLE_CARDS_PORTFOLIO,
  STYLE_INPUT_REVIEW_SOCIAL,
  STYLE_SUBMIT_BUTTON,
} from "../../../lib/style";

const RenderOurProject = () => {
  const { notifySuccess } = useToastify();

  return (
    <Page
      api={api}
      path="/controllers"
      mode="main"
      params={{ type: "PROJECT" }}
      navigate={navigator}
      renderBody={({ data, loading }) => {
        const onlyProject =
          data?.controllers?.filter((item) => item.type === "PROJECT") || [];

        const onSubmit = async (form, index) => {
          const image_key = await FunctionUploadFileAws(form?.received?.file);
          const link = form?.received?.link;
          try {
            if (onlyProject[index]?._id) {
              await api.put(`/controllers/${onlyProject[index]?._id}`, {
                ...form,
                image_key,
                received: image_key,
                link,
                type: "PROJECT",
              });
              notifySuccess();
            } else {
              await api.post(`/controllers`, {
                ...form,
                image_key,
                received: image_key,
                link,
                type: "PROJECT",
              });
              notifySuccess();
            }
          } catch (error) {
            console.error("ERROR TO SUBMIT PORTFOLIO : ", error);
          }
        };

        if (loading) {
          return <div>Loading...</div>;
        }
        return (
          <div className="grid grid-cols-3 gap-4">
            <div className="my-10">
              <Controllers
                loadData={{
                  received: {
                    link: onlyProject[0]?.link,
                    file: onlyProject[0]?.received,
                  },
                }}
                onSubmit={(data) => {
                  onSubmit(data, 0);
                }}
                classNameSubmit={`${STYLE_SUBMIT_BUTTON} mt-2 `}
                classNameWrapper="flex gap-3 items-center justify-center"
                fields={[
                  {
                    type: "customs",
                    name: "received",
                    emitter: "type",
                    recipient: true,
                    required: " true ",
                    renderError: ({ error }) => {
                      return "";
                    },
                    fieldCustoms: ({ value, setValue, name }) => {
                      return (
                        <div
                          className={`${STYLE_CARDS_PORTFOLIO} lg:h-80 xl:h-96 relative `}
                        >
                          <div className=" w-full h-40 object-contain mx-auto">
                            <FieldUploadFile
                              value={value.file}
                              setValue={(file) => {
                                setValue({
                                  ...value,
                                  file: file,
                                });
                              }}
                              name={name}
                              getPathUrl={CloudfrontUrl}
                            />
                          </div>
                          <div className="absolute bottom-0 w-11/12">
                            <FieldText
                              value={value?.link}
                              onChange={(e) => {
                                setValue({
                                  ...value,
                                  link: e.target.value,
                                });
                              }}
                              name={name}
                              placeholder="ป้อนข้อความ"
                              className={STYLE_INPUT_REVIEW_SOCIAL}
                            />
                          </div>
                        </div>
                      );
                    },
                  },
                ]}
              />
            </div>

            <div className="my-10 ">
              <Controllers
                loadData={{
                  received: {
                    link: onlyProject[1]?.link,
                    file: onlyProject[1]?.received,
                  },
                }}
                onSubmit={(data) => {
                  onSubmit(data, 1);
                }}
                classNameSubmit={`${STYLE_SUBMIT_BUTTON} mt-2 `}
                classNameWrapper="flex gap-3 items-center justify-center"
                fields={[
                  {
                    type: "customs",
                    name: "received",
                    emitter: "type",
                    // recipient: true,
                    required: " true ",
                    renderError: ({ error }) => {
                      return "";
                    },
                    fieldCustoms: ({ value, setValue, name }) => {
                      try {
                        const link =
                          value?.link && FunctionGetYouTubeId(value?.link);
                        return (
                          <div
                            className={`${STYLE_CARDS_PORTFOLIO} lg:h-80 xl:h-96 relative`}
                          >
                            {link && (
                              <div className="w-full h-40 object-contain mx-auto">
                                <Iframe
                                  url={`https://www.youtube.com/embed/${link}`}
                                  width="100%"
                                  height="100%"
                                  display="initial"
                                  position="relative"
                                  className="shadow-xl"
                                />
                              </div>
                            )}
                            <div className="absolute bottom-0 w-11/12">
                              <FieldText
                                isLoad={data?.controller?._id ? true : false}
                                value={value?.link}
                                onChange={(e) => {
                                  setValue({
                                    ...value,
                                    link: e?.target?.value,
                                  });
                                }}
                                name={name}
                                // placeholder="เฉพาะ ID เช่น KDKva-s_khY"
                                placeholder="วาง link youtube"
                                className={STYLE_INPUT_REVIEW_SOCIAL}
                              />

                              <FieldText
                                value={value?.file}
                                onChange={(e) => {
                                  setValue({
                                    ...value,
                                    file: e.target.value,
                                  });
                                }}
                                name={name}
                                placeholder="ป้อนข้อความ"
                                className={STYLE_INPUT_REVIEW_SOCIAL}
                              />
                            </div>
                          </div>
                        );
                      } catch (error) {
                        console.error("STFU");
                      }
                    },
                  },
                ]}
              />
            </div>

            <div className="my-10 ">
              <Controllers
                loadData={{
                  received: {
                    link: onlyProject[2]?.link,
                    file: onlyProject[2]?.received,
                  },
                }}
                onSubmit={(data) => {
                  onSubmit(data, 2);
                }}
                classNameSubmit={`${STYLE_SUBMIT_BUTTON} mt-2 `}
                classNameWrapper="flex gap-3 items-center justify-center"
                fields={[
                  {
                    type: "customs",
                    name: "received",
                    emitter: "type",
                    recipient: true,
                    required: " true ",
                    renderError: ({ error }) => {
                      return "";
                    },
                    fieldCustoms: ({ value, setValue, name }) => {
                      return (
                        <div
                          className={`${STYLE_CARDS_PORTFOLIO} lg:h-80 xl:h-96 relative`}
                        >
                          <div className="w-full h-40 object-contain mx-auto">
                            <FieldUploadFile
                              value={value.file}
                              setValue={(file) => {
                                setValue({
                                  ...value,
                                  file: file,
                                });
                              }}
                              name={name}
                              getPathUrl={CloudfrontUrl}
                            />
                          </div>
                          <div className="absolute bottom-0 w-11/12">
                            <FieldText
                              value={value?.link}
                              onChange={(e) => {
                                setValue({
                                  ...value,
                                  link: e.target.value,
                                });
                              }}
                              name={name}
                              placeholder="ป้อนข้อความ"
                              className={STYLE_INPUT_REVIEW_SOCIAL}
                            />{" "}
                          </div>
                        </div>
                      );
                    },
                  },
                ]}
              />
            </div>

            <div className="my-10 ">
              <Controllers
                loadData={{
                  received: {
                    link: onlyProject[3]?.link,
                    file: onlyProject[3]?.received,
                  },
                }}
                onSubmit={(data) => {
                  onSubmit(data, 3);
                }}
                classNameSubmit={`${STYLE_SUBMIT_BUTTON} mt-2 `}
                classNameWrapper="flex gap-3 items-center justify-center"
                fields={[
                  {
                    type: "customs",
                    name: "received",
                    emitter: "type",
                    recipient: true,
                    required: " true ",
                    renderError: ({ error }) => {
                      return "";
                    },
                    fieldCustoms: ({ value, setValue, name }) => {
                      return (
                        <div>
                          <div
                            className={`${STYLE_CARDS_PORTFOLIO} lg:h-80 xl:h-96 relative`}
                          >
                            <div className="w-full h-40 object-contain mx-auto">
                              <FieldUploadFile
                                value={value.file}
                                setValue={(file) => {
                                  setValue({
                                    ...value,
                                    file: file,
                                  });
                                }}
                                name={name}
                                getPathUrl={CloudfrontUrl}
                              />
                            </div>
                            <div className="absolute bottom-0 w-11/12">
                              <FieldText
                                value={value?.link}
                                onChange={(e) => {
                                  setValue({
                                    ...value,
                                    link: e.target.value,
                                  });
                                }}
                                name={name}
                                placeholder="ป้อนข้อความ"
                                className={STYLE_INPUT_REVIEW_SOCIAL}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    },
                  },
                ]}
              />
            </div>

            <div className="my-10 ">
              <Controllers
                loadData={{
                  received: {
                    link: onlyProject[4]?.link,
                    file: onlyProject[4]?.received,
                  },
                }}
                onSubmit={(data) => {
                  onSubmit(data, 4);
                }}
                classNameSubmit={`${STYLE_SUBMIT_BUTTON} mt-2 `}
                classNameWrapper="flex gap-3 items-center justify-center"
                fields={[
                  {
                    type: "customs",
                    name: "received",
                    emitter: "type",
                    recipient: true,
                    required: " true ",
                    renderError: ({ error }) => {
                      return "";
                    },
                    fieldCustoms: ({ value, setValue, name }) => {
                      return (
                        <div>
                          <div
                            className={`${STYLE_CARDS_PORTFOLIO} lg:h-80 xl:h-96 relative`}
                          >
                            <div className="w-full h-40 object-contain mx-auto">
                              <FieldUploadFile
                                value={value.file}
                                setValue={(file) => {
                                  setValue({
                                    ...value,
                                    file: file,
                                  });
                                }}
                                name={name}
                                getPathUrl={CloudfrontUrl}
                              />
                            </div>
                            <div className="absolute bottom-0 w-11/12">
                              <FieldText
                                value={value?.link}
                                onChange={(e) => {
                                  setValue({
                                    ...value,
                                    link: e.target.value,
                                  });
                                }}
                                name={name}
                                placeholder="ป้อนข้อความ"
                                className={STYLE_INPUT_REVIEW_SOCIAL}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    },
                  },
                ]}
              />
            </div>

            <div className="my-10 ">
              <Controllers
                loadData={{
                  received: {
                    link: onlyProject[5]?.link,
                    file: onlyProject[5]?.received,
                  },
                }}
                onSubmit={(data) => {
                  onSubmit(data, 5);
                }}
                classNameSubmit={`${STYLE_SUBMIT_BUTTON} mt-2 `}
                classNameWrapper="flex gap-3 items-center justify-center"
                fields={[
                  {
                    type: "customs",
                    name: "received",
                    emitter: "type",
                    recipient: true,
                    required: " true ",
                    renderError: ({ error }) => {
                      return "";
                    },
                    fieldCustoms: ({ value, setValue, name }) => {
                      return (
                        <div>
                          <div
                            className={`${STYLE_CARDS_PORTFOLIO} lg:h-80 xl:h-96 relative`}
                          >
                            <div className="w-full h-40 object-contain mx-auto">
                              <FieldUploadFile
                                value={value.file}
                                setValue={(file) => {
                                  setValue({
                                    ...value,
                                    file: file,
                                  });
                                }}
                                name={name}
                                getPathUrl={CloudfrontUrl}
                              />
                            </div>
                            <div className="absolute bottom-0 w-11/12">
                              <FieldText
                                value={value?.link}
                                onChange={(e) => {
                                  setValue({
                                    ...value,
                                    link: e.target.value,
                                  });
                                }}
                                name={name}
                                placeholder="ป้อนข้อความ"
                                className={STYLE_INPUT_REVIEW_SOCIAL}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    },
                  },
                ]}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default RenderOurProject;
