import { useNavigate } from "react-router-dom";
import api from "../../api";
import {
  BunketUrl,
  CloudfrontUrl,
  FunctionUploadFileAws,
} from "../../utils/useFunctions";
import { Controllers, Page } from "radium-omnibus";
import useToastify from "../../utils/useToastify";
import {
  STYLE_BANNER_INPUT,
  STYLE_CANCEL_BUTTON,
  STYLE_LABEL_BANNER,
  STYLE_SUBMIT_BUTTON,
} from "../../lib/style";
import useSweetAlert from "../../utils/useSweetAlert";
import { DeleteButton } from "../../utils/util";
import { controllerFields } from "../../utils/util";

const BannerDetail = () => {
  const router = useNavigate();
  const { notifySuccess, notifyError } = useToastify();
  const { confirm, successDeleteAlert } = useSweetAlert();

  const clickDeletePicture = async (e, key, loadData) => {
    e.stopPropagation();
    await confirm({
      title: "คุณต้องการลบภาพนี้หรือไม่",
      callback: () => onClickDeleteBanner(key, loadData),
    });
  };

  const onClickDeleteBanner = async (key, loadData) => {
    try {
      const dataUpdate = loadData;
      dataUpdate[key] = "";
      await api.put(`/banners/${dataUpdate._id}`, {
        ...dataUpdate,
      });

      successDeleteAlert();
      window.location.reload();
    } catch (error) {
      console.error("ERROR TO DELETE BANNER : ", error);
    } finally {
      router("/banner");
    }
  };

  return (
    <Page
      api={api}
      path="/banners"
      mode="main"
      navigate={navigator}
      renderBody={({ data, loading }) => {
        const loadData = data?.banners?.length === 1 ? data?.banners[0] : {};
        const onSubmit = async (form) => {
          const image_key_home = await FunctionUploadFileAws(
            form?.image_key_home
          );
          const image_key_service = await FunctionUploadFileAws(
            form?.image_key_service
          );
          const image_key_portfolio = await FunctionUploadFileAws(
            form?.image_key_portfolio
          );
          const image_key_our_story = await FunctionUploadFileAws(
            form?.image_key_our_story
          );
          const mobile_home = await FunctionUploadFileAws(form?.mobile_home);
          const mobile_service = await FunctionUploadFileAws(
            form?.mobile_service
          );
          const mobile_portfolio = await FunctionUploadFileAws(
            form?.mobile_portfolio
          );
          const mobile_our_story = await FunctionUploadFileAws(
            form?.mobile_our_story
          );

          try {
            if (loadData._id) {
              await api.put(`/banners/${loadData._id}`, {
                // ...form,
                image_key_home,
                image_key_service,
                image_key_portfolio,
                image_key_our_story,
                mobile_home,
                mobile_service,
                mobile_portfolio,
                mobile_our_story,
              });
              notifySuccess();
              router("/banner");
            } else {
              await api.post(`/banners`, {
                // ...form,
                image_key_home,
                image_key_service,
                image_key_portfolio,
                image_key_our_story,
                mobile_home,
                mobile_service,
                mobile_portfolio,
                mobile_our_story,
              });
              notifySuccess();
              router("/banner");
            }
          } catch (error) {
            console.error("ERROR TO SUBMIT BANNER : ", error);
            notifyError();
          }
        };

        if (loading) {
          return <div>Loading...</div>;
        }
        return (
          <div className="mt-5  ">
            <div className="">
              <Controllers
                loadData={loadData}
                onSubmit={onSubmit}
                labelSubmit="Submit"
                classNameSubmit={STYLE_SUBMIT_BUTTON}
                onCancel={() => {
                  router("/");
                }}
                classNameCancel={STYLE_CANCEL_BUTTON}
                //
                classNameWrapper=" flex gap-3 items-center justify-center "
                fields={[
                  controllerFields(
                    () => <></>,
                    "Banner Home Desktop Size : 3100*1600 px",
                    "image_key_home",
                    "file",
                    CloudfrontUrl,
                    STYLE_LABEL_BANNER,
                    STYLE_BANNER_INPUT
                  ),

                  controllerFields(
                    () => (
                      <DeleteButton
                        onClick={(e) => {
                          clickDeletePicture(e, "image_key_home", loadData);
                        }}
                      />
                    ),
                    "",
                    "",
                    "none"
                  ),

                  controllerFields(
                    () => <></>,
                    "Banner Home Mobile Size : 1080*2337 px",
                    "mobile_home",
                    "file",
                    CloudfrontUrl,
                    STYLE_LABEL_BANNER,
                    STYLE_BANNER_INPUT
                  ),

                  controllerFields(
                    () => (
                      <DeleteButton
                        onClick={(e) => {
                          clickDeletePicture(e, "mobile_home", loadData);
                        }}
                      />
                    ),
                    "",
                    "",
                    "none"
                  ),

                  controllerFields(
                    () => <></>,
                    "Banner Service Desktop Size : 3100*1080 px",
                    "image_key_service",
                    "file",
                    CloudfrontUrl,
                    STYLE_LABEL_BANNER,
                    STYLE_BANNER_INPUT
                  ),

                  {
                    type: "none",
                    render: () => (
                      <DeleteButton
                        onClick={(e) => {
                          clickDeletePicture(e, "image_key_service", loadData);
                        }}
                      />
                    ),
                  },

                  controllerFields(
                    () => <></>,
                    "Banner Service Mobile Size : 1080*2337 px",
                    "mobile_service",
                    "file",
                    CloudfrontUrl,
                    STYLE_LABEL_BANNER,
                    STYLE_BANNER_INPUT
                  ),
                  controllerFields(
                    () => (
                      <DeleteButton
                        onClick={(e) => {
                          clickDeletePicture(e, "mobile_service", loadData);
                        }}
                      />
                    ),
                    "",
                    "",
                    "none"
                  ),

                  controllerFields(
                    () => <></>,
                    "Banner Portfolio Desktop Size : 3100*1080 px",
                    "image_key_portfolio",
                    "file",
                    CloudfrontUrl,
                    STYLE_LABEL_BANNER,
                    STYLE_BANNER_INPUT
                  ),
                  controllerFields(
                    () => (
                      <DeleteButton
                        onClick={(e) => {
                          clickDeletePicture(
                            e,
                            "image_key_portfolio",
                            loadData
                          );
                        }}
                      />
                    ),
                    "",
                    "",
                    "none"
                  ),

                  controllerFields(
                    () => <></>,
                    "Banner Portfolio Mobile Size : 1080*2337 px",
                    "mobile_portfolio",
                    "file",
                    CloudfrontUrl,
                    STYLE_LABEL_BANNER,
                    STYLE_BANNER_INPUT
                  ),
                  controllerFields(
                    () => (
                      <DeleteButton
                        onClick={(e) => {
                          clickDeletePicture(e, "mobile_portfolio", loadData);
                        }}
                      />
                    ),
                    "",
                    "",
                    "none"
                  ),

                  controllerFields(
                    () => <></>,
                    "Banner Our Story Desktop Size : 3100*1080 px",
                    "image_key_our_story",
                    "file",
                    CloudfrontUrl,
                    STYLE_LABEL_BANNER,
                    STYLE_BANNER_INPUT
                  ),
                  controllerFields(
                    () => (
                      <DeleteButton
                        onClick={(e) => {
                          clickDeletePicture(
                            e,
                            "image_key_our_story",
                            loadData
                          );
                        }}
                      />
                    ),
                    "",
                    "",
                    "none"
                  ),

                  controllerFields(
                    () => <></>,
                    "Banner Our Story Mobile Size : 1080*2337 px",
                    "mobile_our_story",
                    "file",
                    CloudfrontUrl,
                    STYLE_LABEL_BANNER,
                    STYLE_BANNER_INPUT,
                    true
                  ),

                  controllerFields(
                    () => (
                      <DeleteButton
                        onClick={(e) => {
                          clickDeletePicture(e, "mobile_our_story", loadData);
                        }}
                      />
                    ),
                    "",
                    "",
                    "none"
                  ),
                ]}
              />
            </div>
          </div>
        );
      }}
    />
  );
};
export default BannerDetail;
