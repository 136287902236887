import ContainerContent from "../../components/layouts/ContainerContent";
import RenderService from "./Controller.Components/RenderService";
import {
  STYLE_BORDER_UNDERLINE,
  STYLE_HEAD,
  STYLE_LABEL_BANNER,
} from "../../lib/style";
import RenderOurProject from "./Controller.Components/RenderOurProject";

const ControllerMain = () => {
  return (
    <ContainerContent>
      <div className=""></div>
      <div className={STYLE_HEAD}>Our PROJECT</div>
      <div className={STYLE_BORDER_UNDERLINE}></div>
      <div className={STYLE_LABEL_BANNER}>*สำหรับแสดงโชว์หน้า Home</div>
      <RenderOurProject />

      <div className={STYLE_HEAD}>Our Service</div>
      <div className={STYLE_BORDER_UNDERLINE}></div>
      <RenderService />

      {/* <RenderInfu /> */}
    </ContainerContent>
  );
};
export default ControllerMain;
