import { useNavigate } from "react-router-dom";
import ContainerContent from "../../components/layouts/ContainerContent";
import {
  ICON_BANNER,
  ICON_BLOG,
  ICON_FORM,
  ICON_PORTFOLIO,
} from "../../lib/icons";
import {
  STYLE_BODY_HOME,
  STYLE_BORDER_CIRCLE,
  STYLE_BORDER_UNDERLINE,
  STYLE_BUTTON_HOME,
  STYLE_FLEX_TITLE,
  STYLE_HEAD,
  STYLE_ICON,
  STYLE_TITLE,
} from "../../lib/style";

const HomeMain = () => {
  const navigate = useNavigate();

  return (
    <ContainerContent>
      <div className={STYLE_HEAD}>Admin Glow On Idea</div>
      <div className={STYLE_BORDER_UNDERLINE}></div>
      <div className=" text-xl  text-glowon-green mt-10 font-light ">
        คำแนะนำสำหรับการใช้งาน
      </div>
      <div className={STYLE_FLEX_TITLE}>
        <div className={STYLE_BORDER_CIRCLE}>
          <ICON_BANNER className={STYLE_ICON} />
        </div>
        <div className={STYLE_TITLE}>Banner</div>
      </div>
      <div className={STYLE_BODY_HOME}>
        <div className="mb-4">Home</div>
        <li> Desktop Size : 3100*1600 px </li>
        <li> Mobile Size : 390*844 px </li>
      </div>
      <div className={STYLE_BODY_HOME}>
        <div className="mb-4">Our story , Portfolio , Service </div>
        <li> Desktop Size : 3100*1080 px </li>
        <li> Mobile Size : 390*844 px </li>
      </div>
      <div className={STYLE_FLEX_TITLE}>
        <div className={STYLE_BORDER_CIRCLE}>
          <ICON_PORTFOLIO className={STYLE_ICON} />
        </div>
        <div className={STYLE_TITLE}>Portfolio</div>
      </div>
      <div className={STYLE_BODY_HOME}>
        <li className="mt-4">
          ปุ่ม +Portfolio โดยข้อมูลที่จำเป็นต้องกรอกจะมี Social Media,
          UploadFile, Title, Description และกดบันทึก
          ซึ่งสามารถแยกประเภทของสื่อสังคมออนไลน์ได้เมื่อคลิกแท็บด้านบน{" "}
        </li>
        {/* <div className="mt-4 text-xs text-glowon-yellow">
          *** ส่วนการอัปลิงค์วีดีโอ ให้ใส่เฉพาะ ID ของลิงก์ เช่น
          https://youtu.be/KDKva-s_khY ใส่เฉพาะ KDKva-s_khY และ
          https://www.tiktok.com/@0tomjerry00/video/7097717624993271045?
          ใส่เฉพาะ 7097717624993271045" ***
        </div> */}
      </div>
      <div className={STYLE_FLEX_TITLE}>
        <div className={STYLE_BORDER_CIRCLE}>
          <ICON_BLOG className={STYLE_ICON} />
        </div>
        <div className={STYLE_TITLE}>Blog</div>
      </div>

      <div className={STYLE_BODY_HOME}>
        <li className="mt-4">
          ปุ่ม +Blog จะเพิ่มข้อมูลและไฟล์รายละเอียดอื่นๆ
          โดยข้อมูลที่จำเป็ต้องกรอกจะมี Title, Description, เลือกรูปภาพ,
          และกรอกข้อมูล Blog กดบันทึก สามารถเพิ่มหรือลบ Blog
          เพื่อจัดการหรือแก้ไขได้
        </li>
      </div>
      <div className={STYLE_FLEX_TITLE}>
        <div className={STYLE_BORDER_CIRCLE}>
          <ICON_FORM className={STYLE_ICON} />
        </div>
        <div className={STYLE_TITLE}>Contact Form</div>
      </div>
      <div className={STYLE_BODY_HOME}>
        <li> Tab Marketer จะเป็นข้อมูลรายละเอียดฟอร์มสมัครการตลาด </li>
      </div>
      <div className={STYLE_BODY_HOME}>
        <li>
          Tab Reviewer สามารถคัดลอก URL Social Media ต่างๆ
          และเมื่อคลิกที่การ์ดจะสามารถไปตามลิ้งค์นั้นๆ ได้{" "}
        </li>
      </div>
    </ContainerContent>
  );
};
export default HomeMain;
