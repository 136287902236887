import React, { useEffect, useState } from "react";
import {
  FunctionAddUrlToFile,
  FunctionGetPathUrl,
  FunctionUploadFileAws,
} from "../../../utils/useFunctions";
import api from "../../../api";
import ContainerContent from "../../../components/layouts/ContainerContent";
import useToastify from "../../../utils/useToastify";
import useSweetAlert from "../../../utils/useSweetAlert";
import RenderMap from "./RenderMap";
import { AddButton, DeleteButton } from "../../../components/attributes/Button";
import {
  STYLE_CONFIRM_BUTTON_CLIENT,
  STYLE_UPLOAD_CLIENT,
} from "../../../lib/style";
import Loading from "../../../components/attributes/Loading/Loading";

const HoldRender = ({ data, edit, isEdit }) => {
  const [number, setNumber] = useState(0);

  useEffect(() => {
    if (data?.number) {
      setNumber(data?.number);
    } else {
      return;
    }
  }, []);

  return (
    <React.Fragment>
      <input
        className=" text-glowon-gray font-semibold text-center  text-xl focus:outline-none"
        value={number}
        disabled={!edit}
        onChange={(e) => {
          setNumber(e.target.value);
        }}
      />
      <AddButton
        title={edit ? "Save" : "Edit"}
        onClick={async () => {
          if (edit) {
            await api.put(`/controllers/${data?._id}`, {
              number: number,
            });
            // refetch();
            window.location.reload();
            isEdit(false);
          } else {
            isEdit(true);
          }
        }}
      />
    </React.Fragment>
  );
};

const RenderEachImage = ({ data, refetch }) => {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const { notifySuccess, notifyError } = useToastify();
  const { confirm, successDeleteAlert } = useSweetAlert();
  const [edit, isEdit] = useState(false);

  useEffect(() => {
    if (data?.image) {
      setValue(data?.image);
    } else {
      return;
    }
  }, []);

  const onClickUpLoadImage = async ({ target: { files } }) => {
    const file = await files[0];
    setValue(file);
  };

  const clickDeleteCard = async (e, id) => {
    e.stopPropagation();
    await confirm({
      title: "คุณต้องการลบการ์ดนี้หรือไม่",
      callback: () => onClickToDeleteCard(id),
    });
  };

  const onClickToDeleteCard = async (id) => {
    try {
      await api.delete(`/controllers/${id}`);
      successDeleteAlert();
    } catch (error) {
      console.error("ERROR TO DELETE SERVICE", error);
    } finally {
      refetch();
    }
  };

  const onClickUpDateImage = async () => {
    setLoading(true);
    try {
      const image_key = await FunctionUploadFileAws(value);
      await api.put(`/controllers/${data?._id}`, {
        image: image_key,
      });
      setValue(image_key);
      notifySuccess();
    } catch (error) {
      console.error("ERROR TO UPDATE IMAGE", error);
    } finally {
      setLoading(false);
      refetch();
    }
  };

  return (
    <div
      className={`text-center`}
      key={`index-reference${data?._id}-${new Date().getTime()}`}
    >
      <label htmlFor={`index-reference${data._id}-${new Date().getTime()}`}>
        <div className={`w-full h-44 border border-glowon-gray rounded-lg p-1`}>
          <div key={data?._id}>
            <div>{data?.name}</div>
            <div>{data?.description}</div>
            {value?.size > 0 ? (
              <img
                className={`h-40 w-40 mx-auto object-contain`}
                src={FunctionAddUrlToFile(value)}
              />
            ) : (
              [
                value ? (
                  <img
                    className={`h-40 w-40 mx-auto object-contain`}
                    src={FunctionGetPathUrl(value)}
                  />
                ) : (
                  <div className={STYLE_UPLOAD_CLIENT}>
                    คลิ๊กเพื่ออัพโหลดภาพ
                  </div>
                ),
              ]
            )}
          </div>
          <input
            accept="image/jpeg,image/gif,image/png"
            className="hidden"
            id={`index-reference${data?._id}-${new Date().getTime()}`}
            type="file"
            onChange={onClickUpLoadImage}
          />
        </div>
      </label>
      {value?.size ? (
        <div
          onClick={() => onClickUpDateImage(data?._id)}
          className={STYLE_CONFIRM_BUTTON_CLIENT}
        >
          UPLOAD IMAGE
        </div>
      ) : (
        <div className=" mt-2 flex flex-col justify-center items-center">
          <HoldRender data={data} edit={edit} isEdit={isEdit} />

          <DeleteButton onClick={(e) => clickDeleteCard(e, data?._id)} />
        </div>
      )}
    </div>
    // <div key={data?._id} className="text-center border border-blue-500 p-2">
    //   <img className="w-40 h-40" src={FunctionGetPathUrl(data?.image)} />
    //   <div>{data?.name}</div>
    //   {isNew ? (
    //     <div>isNew</div>
    // ) : (
    // <div
    //   onClick={() => onClickDeleteTag(data?._id)}
    //   className="bg-red-500 text-white cursor-pointer"
    // >
    //   DELETE
    // </div>
    //   )}
    // </div>
  );
};

const RenderService = () => {
  const [des, setDes] = useState("");

  const [data, setData] = useState([]);

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async () => {
    try {
      const { data } = await api.get("/controllers?type=SERVICE&sort=number:1");
      setData(data?.controllers);
    } catch (error) {
      console.error("ERROR TO LOAD SERVICE LOGO ", error);
    }
  };

  const onClickAddNewService = async () => {
    try {
      await api.post("/controllers", {
        description: des,
        type: "SERVICE",
      });
      setDes("");
      LoadData();
    } catch (error) {
      console.error("ERROR TO ADD SERVICE", error);
    }
  };

  return (
    <React.Fragment>
      <AddButton
        title="Add Image"
        onClick={() => {
          onClickAddNewService();
          window.location.reload();
        }}
      />
      <div className=" grid grid-cols-4 gap-4 mt-10">
        {data?.map((i, index) => {
          return (
            <RenderEachImage
              data={i}
              refetch={LoadData}
              index={index}
              key={index}
            />
          );
        })}
      </div>

      {/* <div> add description </div>
      <input
        className="border-2 w-60 py-4"
        value={des}
        placeholder={"ใส่คำอธิบาย"}
        onChange={(e) => {
          const value = e.target.value;
          setDes(value);
        }}
      /> */}
      {/* <RenderMap /> */}
    </React.Fragment>
  );
};
export default RenderService;
