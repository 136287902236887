import React from "react";
import { useNavigate } from "react-router-dom";
import { DeleteButton } from "../../../components/attributes/Button";
import { ICON_DELETE } from "../../../lib/icons";
import { STYLE_CARDS_PORTFOLIO } from "../../../lib/style";
import { FunctionGetPathUrl } from "../../../utils/useFunctions";

const FacebookRender = ({ portfolio, index, clickDeleteCard }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(`/Portfolio/${portfolio?._id}`);
      }}
      key={index}
    >
      <img
        className=" w-full h-56 object-cover "
        src={FunctionGetPathUrl(portfolio?.image_key)}
      />

      <div className=" text-xl  pt-2 px-3 flex my-3">{portfolio?.title}</div>
      <div className="  font-light pt-1 line-clamp-2  px-3 ">
        {portfolio?.description}
      </div>

      <DeleteButton onClick={(e) => clickDeleteCard(e, portfolio?._id)} />
    </div>
  );
};

export default FacebookRender;
