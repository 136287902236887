import React, { useEffect, useState } from "react";
import ContainerContent from "../../components/layouts/ContainerContent";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import { Delete_Button_Market } from "../../lib/icons";
import {
  STYLE_BORDER_UNDERLINE,
  STYLE_HEAD,
  STYLE_HEAD_TABLE_MARKET,
  STYLE_TABLE_MARKET,
} from "../../lib/style";
import { reNameSocial } from "../../utils/constant";
import useSweetAlert from "../../utils/useSweetAlert";
import { DeleteButtonTable } from "../../components/attributes/Button";

const FormMarketer = () => {
  const navigate = useNavigate();
  const [maketerData, setmaketerData] = useState([]);
  console.log("maketerData", maketerData);
  const { confirm, successDeleteAlert } = useSweetAlert();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const contact_type = "marketer";
      const { data } = await api.get(`/contacts?contact_type=${contact_type}`);
      setmaketerData(data);
    } catch (error) {
      console.error("ERROR TO LOAD MARKETER : ", error);
    }
  };

  const clickDeleteCard = async (e, id) => {
    e.stopPropagation();
    await confirm({
      title: "คุณต้องการลบการ์ดนี้หรือไม่",
      callback: () => onClickToDeleteCard(id),
    });
  };

  const onClickToDeleteCard = async (id) => {
    try {
      await api.delete(`/contacts/${id}`);
      successDeleteAlert();

      // navigate("/contacts");
    } catch (error) {
      console.error("ERROR TO DELETE PORTFOLIO : ", error);
    } finally {
      loadData();
    }
  };

  return (
    <React.Fragment>
      <div className={`${STYLE_HEAD} mt-5`}> Marketer Form</div>
      <div className={STYLE_BORDER_UNDERLINE}></div>
      <div className="overflow-auto lg:overflow-visible my-10 ">
        <div className={STYLE_HEAD_TABLE_MARKET}>
          <div className=" col-span-2 ">ชื่อ-นามสกุล</div>
          <div className=" col-span-2">บริษัท </div>
          <div className=" col-span-2">ประเภทธุรกิจ</div>
          <div className=" col-span-2">อีเมล</div>
          <div className=" col-span-1">เบอร์โทร</div>
          <div className=" col-span-2">ช่องทางที่สนใจ</div>
        </div>
        {maketerData?.contacts?.map((row, index) => {
          return (
            <div key={index} className="divFirstArea">
              <div className={STYLE_TABLE_MARKET}>
                <div className=" flex justify-center items-center gap-4 col-span-2">
                  {row?.name} {row?.surname}
                </div>
                <div className=" col-span-2">{row?.company}</div>
                <div className=" col-span-2">{row?.business_type}</div>
                <div className=" col-span-2">{row?.email}</div>
                <div className=" col-span-1">{row?.phone}</div>
                <div className=" col-span-2">
                  {row?.interesting_social_media_type
                    ?.filter?.((item) => item.checked === true)
                    ?.map((row, index) => {
                      return <div key={index}>{reNameSocial[row?.option]}</div>;
                    })}
                </div>
                <DeleteButtonTable
                  onClick={(e) => {
                    clickDeleteCard(e, row?._id);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};
export default FormMarketer;
