import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userStore } from "../../utils/user_store";

function ProtecedRoute({ children }) {
  const [firstRender, setFirstRender] = useState(true);
  const user = userStore((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }
    setFirstRender(false);
  }, [user]);

  if (firstRender) {
    return null;
  }

  return children;
}

export default ProtecedRoute;
