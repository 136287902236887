import { Navigate } from "react-router-dom";
import { FunctionGetPathUrl } from "../../../utils/useFunctions";
import { format } from "date-fns";
import { ICON_DELETE } from "../../../lib/icons";
import { STYLE_CARDS_BLOG } from "../../../lib/style";
import { id } from "date-fns/locale";
import useSweetAlert from "../../../utils/useSweetAlert";
import { DeleteButton } from "../../../components/attributes/Button";

export const BlogCard = ({ blogs, deleteBlog, editBlogs }) => {
  const displayDate = (createdAt) => {
    return format(new Date(createdAt), "dd/MM/yyyy");
  };

  const { confirm } = useSweetAlert();
  const clickDeleteCard = async (e, id) => {
    e.stopPropagation();
    await confirm({
      title: "คุณต้องการลบการ์ดนี้หรือไม่",
      callback: () => deleteBlog(id),
    });
  };

  return (
    <div className=" mx-auto h-full font-Prompt">
      <div className="grid grid-cols-3 gap-6 mt-5">
        {blogs?.length > 0 &&
          blogs?.map((blog, index) => {
            return (
              <div
                key={index}
                className={STYLE_CARDS_BLOG}
                onClick={() => {
                  editBlogs(blog?._id);
                }}
              >
                <div>
                  <img
                    src={FunctionGetPathUrl(blog?.image_key)}
                    className=" w-full h-44 object-cover cursor-pointer"
                    onClick={() => {
                      Navigate(`/Blog/${blog?._id}`);
                    }}
                  />
                  <div className="grid grid-cols-1 ">
                    <div className=" text-xl  pt-2 px-3  "> {blog?.title}</div>
                    <div className="  font-light pt-1 line-clamp-2  px-3 ">
                      {blog?.description}
                    </div>
                    <div className=" font-light pt-1 line-clamp-2  px-3 text-right mb-2">
                      {displayDate(blog?.createdAt)}
                    </div>
                  </div>
                </div>{" "}
                <DeleteButton onClick={(e) => clickDeleteCard(e, blog?._id)} />
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default BlogCard;
