import { useNavigate } from "react-router-dom";
import ContainerContent from "../../components/layouts/ContainerContent";
import { STYLE_BORDER_UNDERLINE, STYLE_HEAD } from "../../lib/style";

import BannerDetail from "./BannerDetail";

const BannerMain = () => {
  const navigate = useNavigate();
  const stylecard =
    "    transform   hover:scale-105 duration-500 border-2 border-sirisagold bg-white  cursor-pointer w-full h-28  flex justify-center items-center gap-2";
  return (
    <ContainerContent>
      <div className={STYLE_HEAD}>Banner</div>
      <div className={STYLE_BORDER_UNDERLINE}></div>
      <BannerDetail />
    </ContainerContent>
  );
};
export default BannerMain;
