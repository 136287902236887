import * as React from "react";

const FieldText = ({
  name,
  value,
  setValue,
  placeholder,
  onChange,
  received,
  clearForm,
  className,
}) => {
  React.useEffect(() => {
    if (clearForm) {
      clearForm();
    }
  }, [received]);

  return (
    <React.Fragment>
      <div className="my-5">
        <input
          placeholder={placeholder}
          className={className}
          name={name}
          id={name}
          type="text"
          value={value}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            } else {
              setValue(e?.target?.value);
            }
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default FieldText;
