import React from "react";
import { ICON_ADD, ICON_DELETE } from "../../lib/icons";
import { STYLE_ADD_BUTTON } from "../../lib/style";

export const Button = ({
  text,
  onClick,
  className = " cursor-pointer  w-44  p-1 bg-sirisagold duration-300  hover:bg-opacity-80 rounded-full flex gap-2 justify-center items-center text-sirisadarkbrown  text-sm ",
  svg,
}) => {
  return (
    <button onClick={(e) => onClick(e)} className={className}>
      {text}
      {svg}
    </button>
  );
};

export const DeleteButton = ({ onClick }) => {
  return (
    <div className=" flex justify-end">
      <div
        onClick={onClick}
        className=" p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-40 border border-red-700 hover:border-red-500 hover:bg-red-500 text-red-700 hover:text-white  rounded-full duration-300  "
      >
        <ICON_DELETE className="w-5" />
        <div>Delete</div>
      </div>
    </div>
  );
};

export const AddButton = ({ onClick, title }) => {
  return (
    <div className="flex justify-end my-5">
      <button type="button" className={STYLE_ADD_BUTTON} onClick={onClick}>
        <ICON_ADD />
        {title}
      </button>
    </div>
  );
};

export const DeleteButtonTable = ({ onClick }) => {
  return (
    <button
      className=" text-red-700 hover:text-red-500 duration-300 "
      onClick={onClick}
    >
      <ICON_DELETE className="w-8 mx-auto" />
    </button>
  );
};
