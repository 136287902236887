import { useState } from "react";
import { userStore } from "../../utils/user_store";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import { fi } from "date-fns/locale";

const LoginMain = () => {
  const { login, isAuthenticated } = userStore();
  const [userLogin, setUserLogin] = useState("");
  const [password, setPassword] = useState("");

  const router = useNavigate();

  const correctUser = "Glowonideaacwork";
  const correctPassword = "Sanchez888";

  const submitLogin = async () => {
    try {
      //   const { user } = await api.get(
      //     `/user/login?username=${userLogin}&password=${password}`
      //   );

      //   if (user) {
      //     login(user);

      //     console.log("user", user);
      //   }
      if (userLogin === correctUser && password === correctPassword) {
        login(userLogin);
        console.log("user", userLogin);
      } else {
        alert("Username or Password is incorrect");
      }
    } catch (error) {
      console.error("ERROR TO LOGIN :", error);
    } finally {
      if (isAuthenticated) {
        router("/");
      }
    }
  };

  return (
    <div
      style={{
        backgroundImage: "url(/login.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      //   className="h-screen w-screen "
      className="h-1/2 lg:h-screen 3xl:ml-[72px] 23xl:ml-0 w-full mx-auto  flex  items-center justify-center 23xl:-mt-[100px] "
    >
      <div className=" bg-white w-[608px] h-[714px] flex items-center justify-center rounded-[30px]">
        <div className="mx-auto mt-32 lg:mt-0 lg:mx-0">
          <div className="w-full max-w-sm items-center gap-1.5">
            <div className="mb-12 flex items-center justify-center">
              <img alt="login" src="login_icon.png" className="w-48 h-48" />
            </div>
            <div
              htmlFor="email"
              className="font-cinzel mb-[10px] mt-[18px]  text-base 3xl:text-base text-glowon-text-green font-medium"
            >
              Username
            </div>
            <input
              type="email"
              id="email"
              //   onChange={(e) => setEmail(e.target.value)}
              className="border border-glowon-bordergray rounded-[5px] w-[380px] h-[44px] pl-2"
              onChange={(e) => setUserLogin(e.target.value)}
            />
          </div>
          <div className="w-full max-w-sm items-center gap-1.5 mt-[18px]">
            <div
              htmlFor="password"
              className="font-cinzel mb-[10px]   text-base 3xl:text-base text-glowon-text-green font-medium"
            >
              Password
            </div>
            <input
              type="password"
              id="password"
              className="border border-glowon-bordergray rounded-[5px] w-[380px] h-[44px] pl-2"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  submitLogin();
                }
              }}
            />
          </div>
          <button
            onClick={submitLogin}
            className="mt-10 w-[380px] h-[40px] text-base  text-white bg bg-glowon-deeppink rounded-[20px]"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginMain;
