import api from "../../api";
import React, { useEffect, useState } from "react";
import {
  ICON_DELETE,
  FacebookIcon,
  InstagramIcon,
  TiktokIcon,
  TwitterIcon,
  ICON_USER,
  ICON_GENDER,
  ICON_AGE,
  ICON_HOME,
  ICON_LINE,
  ICON_PHONE,
  ICON_BLOG,
} from "../../lib/icons";
import _ from "lodash";
import {
  STYLE_BORDER_UNDERLINE,
  STYLE_CARD_REVIEW,
  STYLE_CARD_REVIEW_SOCIAL,
  STYLE_HEAD,
  STYLE_ICON_REVIEW,
  STYLE_INPUT_REVIEW_SOCIAL,
  STYLE_PARENT_CARD_REVIEW_SOCAIL,
  STYLE_TEXT_INPUT_REVIEW_SOCIAL,
} from "../../lib/style";
import useSweetAlert from "../../utils/useSweetAlert";
import { STYLE_FORMREVIEWER_TEXT } from "../../lib/style";
import { STYLE_FORMREVIEWER_FONT } from "../../lib/style";
import { DeleteButton } from "../../components/attributes/Button";
import useToastify from "../../utils/useToastify";

const FormReviewer = () => {
  const [reviewerData, setReviewerData] = useState([]);
  console.log("reviewerData", reviewerData);
  const { confirm, successDeleteAlert } = useSweetAlert();
  const { notifySuccess, notifyError } = useToastify();
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const contact_type = "reviewer";
      const { data } = await api.get(`/contacts?contact_type=${contact_type}`);
      setReviewerData(data);
    } catch (error) {
      console.error("ERROR TO LOAD REVIEW : ", error);
    }
  };

  const clickDeleteCard = async (e, id) => {
    e.stopPropagation();
    await confirm({
      title: "คุณต้องการลบการ์ดนี้หรือไม่",
      callback: () => onClickToDeleteCard(id),
    });
  };

  const onClickToDeleteCard = async (id) => {
    try {
      await api.delete(`/contacts/${id}`);
      successDeleteAlert();
    } catch (error) {
      console.error("ERROR TO DELETE REVIEWER CARD : ", error);
    } finally {
      loadData();
    }
  };

  const copyURL = (url) => {
    const el = document.createElement("input");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    notifySuccess("คัดลอกลิงค์เรียบร้อยแล้ว");
  };

  return (
    <React.Fragment>
      <div className={`${STYLE_HEAD} mt-5`}> Reviewer Form</div>
      <div className={STYLE_BORDER_UNDERLINE}></div>
      <div className="grid grid-cols-3 gap-6 w-full">
        {reviewerData?.contacts?.map((row, index) => {
          console.log("reviewerData : ", reviewerData);
          const yesOrNo = row?.write_blog === "yes" ? "ได้" : "ไม่ได้";
          //followers
          const follower_facebook = _.get(
            row,
            "social_media.facebook.amount_followes.label"
          );
          const follower_twitter = _.get(
            row,
            "social_media.twitter.amount_followes.label"
          );
          const follower_instagram = _.get(
            row,
            "social_media.instagram.amount_followes.label"
          );
          const follower_tiktok = _.get(
            row,
            "social_media.tiktok.amount_followes.label"
          );
          //name
          const name_fecebook = _.get(
            row,
            "social_media.facebook.account_name"
          );
          const name_twitter = _.get(row, "social_media.twitter.account_name");
          const name_instagram = _.get(
            row,
            "social_media.instagram.account_name"
          );
          const name_tiktok = _.get(row, "social_media.tiktok.account_name");
          //url
          const open_facebook = _.get(row, "social_media.facebook.account_url");
          const open_twitter = _.get(row, "social_media.twitter.account_url");
          const open_instagram = _.get(
            row,
            "social_media.instagram.account_url"
          );
          const open_tiktok = _.get(row, "social_media.tiktok.account_url");
          //condition
          const condition_facebook = _.get(row, "social_media.facebook.active");
          const condition_twitter = _.get(row, "social_media.twitter.active");
          const condition_instagram = _.get(
            row,
            "social_media.instagram.active"
          );
          const condition_tiktok = _.get(row, "social_media.tiktok.active");
          //url
          const url_facebook = _.get(row, "social_media.facebook.account_url");
          const url_twitter = _.get(row, "social_media.twitter.account_url");
          const url_instagram = _.get(
            row,
            "social_media.instagram.account_url"
          );
          const url_tiktok = _.get(row, "social_media.tiktok.account_url");

          return (
            <div key={index} className="mt-5">
              <div className={STYLE_CARD_REVIEW}>
                <div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className={STYLE_FORMREVIEWER_TEXT}>
                      <ICON_USER className="w-4" />
                      ชื่อ - นามสกุล :
                    </div>
                    <div className={STYLE_FORMREVIEWER_FONT}>
                      {row?.name} - {row?.surname}
                    </div>
                    <div className="flex gap-2">
                      <div className={STYLE_FORMREVIEWER_TEXT}>
                        <ICON_GENDER className="w-4" />
                        เพศ :{" "}
                      </div>
                      <div className={STYLE_FORMREVIEWER_FONT}>
                        {row?.gender}
                      </div>
                    </div>
                    <div className="flex gap-2">
                      <div className={STYLE_FORMREVIEWER_TEXT}>
                        <ICON_AGE className="w-4" />
                        อายุ :{" "}
                      </div>
                      <div className={STYLE_FORMREVIEWER_FONT}>{row?.age}</div>
                    </div>

                    <div className={STYLE_FORMREVIEWER_TEXT}>
                      <ICON_HOME className="w-4" />
                      จังหวัด :{" "}
                    </div>
                    <div className={STYLE_FORMREVIEWER_FONT}>
                      {row?.province}
                    </div>
                    <div className={STYLE_FORMREVIEWER_TEXT}>
                      <ICON_LINE className="w-4" />
                      Line ID :{" "}
                    </div>
                    <div className={STYLE_FORMREVIEWER_FONT}>
                      {row?.line_id}
                    </div>
                    <div className={STYLE_FORMREVIEWER_TEXT}>
                      <ICON_PHONE className="w-4" />
                      เบอร์โทร :{" "}
                    </div>
                    <div className={STYLE_FORMREVIEWER_FONT}>{row?.phone}</div>
                    <div className={STYLE_FORMREVIEWER_TEXT}>
                      <ICON_BLOG className="w-4" />
                      เขียนบล็อก :{" "}
                    </div>
                    <div className={STYLE_FORMREVIEWER_FONT}>{yesOrNo}</div>
                  </div>

                  {condition_facebook === true && (
                    <div
                      className={STYLE_CARD_REVIEW_SOCIAL}
                      onClick={() => {
                        window.open(open_facebook);
                      }}
                    >
                      <div className={STYLE_PARENT_CARD_REVIEW_SOCAIL}>
                        <FacebookIcon className="w-8 mx-auto" />
                        <div className="col-span-4">
                          <div className={STYLE_FORMREVIEWER_FONT}>
                            {name_fecebook}
                          </div>
                          <div className={STYLE_FORMREVIEWER_TEXT}>
                            {" "}
                            ผู้ติดตาม :
                            <span className={STYLE_FORMREVIEWER_FONT}>
                              {follower_facebook}
                            </span>
                          </div>

                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              copyURL(url_facebook);
                            }}
                            className={STYLE_INPUT_REVIEW_SOCIAL}
                          >
                            <div className={STYLE_TEXT_INPUT_REVIEW_SOCIAL}>
                              {url_facebook}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {condition_twitter === true && (
                    <div
                      className={STYLE_CARD_REVIEW_SOCIAL}
                      onClick={() => {
                        window.open(open_twitter);
                      }}
                    >
                      <div className={STYLE_PARENT_CARD_REVIEW_SOCAIL}>
                        <TwitterIcon className="w-8 mx-auto" />
                        <div className="col-span-4">
                          <div className={STYLE_FORMREVIEWER_FONT}>
                            {name_twitter}
                          </div>
                          <div className={STYLE_FORMREVIEWER_TEXT}>
                            {" "}
                            ผู้ติดตาม :
                            <span className={STYLE_FORMREVIEWER_FONT}>
                              {follower_twitter}
                            </span>
                          </div>

                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              copyURL(url_twitter);
                            }}
                            className={STYLE_INPUT_REVIEW_SOCIAL}
                          >
                            <div className={STYLE_TEXT_INPUT_REVIEW_SOCIAL}>
                              {url_twitter}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {condition_instagram === true && (
                    <div
                      className={STYLE_CARD_REVIEW_SOCIAL}
                      onClick={() => {
                        window.open(open_instagram);
                      }}
                    >
                      <div className={STYLE_PARENT_CARD_REVIEW_SOCAIL}>
                        <InstagramIcon className="w-9 mx-auto" />
                        <div className="col-span-4">
                          <div className={STYLE_FORMREVIEWER_FONT}>
                            {name_instagram}
                          </div>
                          <div className={STYLE_FORMREVIEWER_TEXT}>
                            ผู้ติดตาม :
                            <span className={STYLE_FORMREVIEWER_FONT}>
                              {follower_instagram}
                            </span>
                          </div>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              copyURL(url_instagram);
                            }}
                            className={STYLE_INPUT_REVIEW_SOCIAL}
                          >
                            <div className={STYLE_TEXT_INPUT_REVIEW_SOCIAL}>
                              {url_instagram}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {condition_tiktok === true && (
                    <div
                      className={STYLE_CARD_REVIEW_SOCIAL}
                      onClick={() => {
                        window.open(open_tiktok);
                      }}
                    >
                      {" "}
                      <div className={STYLE_PARENT_CARD_REVIEW_SOCAIL}>
                        <TiktokIcon className="w-6 mx-auto" />

                        <div className="col-span-4">
                          <div className={STYLE_FORMREVIEWER_FONT}>
                            {name_tiktok}
                          </div>
                          <div className={STYLE_FORMREVIEWER_TEXT}>
                            {" "}
                            ผู้ติดตาม :{" "}
                            <span className={STYLE_FORMREVIEWER_FONT}>
                              {follower_tiktok}{" "}
                            </span>
                          </div>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              copyURL(url_tiktok);
                            }}
                            className={STYLE_INPUT_REVIEW_SOCIAL}
                          >
                            <div className={STYLE_TEXT_INPUT_REVIEW_SOCIAL}>
                              {url_tiktok}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <DeleteButton onClick={(e) => clickDeleteCard(e, row?._id)} />
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};
export default FormReviewer;
