import React from "react";

function ContainerContent({ children, ...props }) {
  let customClassName = props?.className;
  return (
    <div
      className={`relative  px-4 xl:px-12  py-20  ${
        customClassName ? customClassName : ""
      }`}
    >
      {children}
    </div>
  );
}

export default ContainerContent;
