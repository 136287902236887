import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ACTIVE_STYLE, INACTIVE_STYLE } from "../../lib/style";
import { userStore } from "../../utils/user_store";

// import {
//   BrowserRouter as Router,
//   Switch,
//   useLocation
// } from "react-router-dom";

export default function Sidebar({ setShowSidebar, showSidebar, routes }) {
  const [collapseShow, setCollapseShow] = useState("hidden");

  const location = useLocation();

  const isLogin = location.pathname === "/login";

  const { logout } = userStore();

  const router = useNavigate();

  const submitLogout = () => {
    logout();
    router("/login");
  };

  // console.log("isLogin=", isLogin);

  const commonStyle = " block duration-300 transition-all font-light ";
  const activeStyle =
    " block duration-300 transition-all  text-glowon-yellowbutton  border-b-2 border-glowon-yellowbutton font-bold ";

  const getNavLink = (props) => {
    return (
      <li className=" items-center ">
        <NavLink
          {...props}
          activeStyle={{
            color: "#DBB77D",
          }}
          className={props?.isActive ? activeStyle : commonStyle}
        >
          <div className="flex justify-items-center text-glowon-gray hover:text-glowon-green duration-300  space-y-10 ">
            <span className="">{props.icon}</span>
            <span className="text-center text-lg">{props.text}</span>
          </div>
        </NavLink>
      </li>
    );
  };

  if (!showSidebar)
    return (
      <div
        className="fixed top-0 left-0  text-white z-20 p-3"
        onClick={() => setShowSidebar(true)}
      ></div>
    );

  return (
    <>
      {!isLogin ? (
        <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden bg-glowon-bgstorytype flex flex-wrap items-center justify-between relative md:w-52 z-10 py-10">
          <div className="flex flex-col justify-between items-center  space-y-16">
            <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-center  w-full mx-auto font-Prompt">
              <img alt="" src="logo.png" className=" w-20 mx-auto" />

              <button
                className="cursor-pointer  opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                type="button"
                onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
              ></button>

              <div
                className={
                  "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                  collapseShow
                }
              >
                <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                  <div className="flex flex-wrap">
                    <div className="w-6/12">
                      <a
                        href="/"
                        className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                      >
                        Template
                      </a>
                    </div>
                    <div className="w-6/12 flex justify-end">
                      <button
                        type="button"
                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded  border border-solid border-transparent"
                        onClick={() => setCollapseShow("hidden")}
                      ></button>
                    </div>
                  </div>
                </div>

                <ul
                  className=" md:flex-col md:min-w-full flex flex-col justify-center items-center list-none "
                  onClick={() => setCollapseShow("hidden")}
                >
                  {routes
                    ?.filter((i) => {
                      return i.show !== false;
                    })
                    ?.map((route) => {
                      let isActive = false;

                      if (route.path === "/") {
                        isActive = location.pathname === route.path;
                      } else {
                        isActive = location.pathname.includes(route.path);
                      }

                      return getNavLink({
                        text: route.title,
                        to: route.path,
                        isActive,
                      });
                    })}
                </ul>
              </div>
            </div>

            <div className="cursor-pointer" onClick={submitLogout}>
              <div className="flex justify-items-center text-glowon-gray hover:text-glowon-green">
                {" "}
                Log out
              </div>
            </div>
          </div>
        </nav>
      ) : null}
    </>
  );
}
