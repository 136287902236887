import { useLocation } from "react-router-dom";

const ContainerRouteLocation = ({ showSidebar, children }) => {
  const location = useLocation();
  const isLogin = location.pathname === "/login";

  return (
    <div
      className={
        "relative font-Kanit " + (showSidebar && !isLogin ? "md:ml-64" : "")
      }
    >
      {children}
    </div>
  );
};

export default ContainerRouteLocation;
