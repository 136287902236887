import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerContent from "../../components/layouts/ContainerContent";
import api from "../../api";
import BlogCard from "./render/BlogCard";
import { ICON_ADD } from "../../lib/icons";
import {
  ADD_BUTTON_BLOG,
  STYLE_BORDER_UNDERLINE,
  STYLE_HEAD,
} from "../../lib/style";
import useSweetAlert from "../../utils/useSweetAlert";
import { AddButton } from "../../components/attributes/Button";
const BlogMain = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const { confirm, successDeleteAlert } = useSweetAlert();

  useEffect(() => {
    loadBlogs();
  }, []);

  const loadBlogs = async () => {
    try {
      setLoading(true);
      const { data: responseData } = await api.get("/blogs?sort=createdAt:-1");
      setBlogs(responseData?.blogs);
      console.log("responseData", responseData);
    } catch (error) {
      console.log("ERROR TO LOAD BLOGS :", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteBlog = async (id) => {
    try {
      setLoading(true);
      await api.delete(`/blogs/${id}`);
      successDeleteAlert();
      loadBlogs();
    } catch (error) {
      console.error("ERROR TO DELETE BLOG :", error);
    } finally {
      setLoading(false);
    }
  };

  const editBlogs = async (id) => {
    navigate(`/Blog/${id}`);
  };

  return (
    <ContainerContent>
      <div className={STYLE_HEAD}>Blog</div>
      <div className={STYLE_BORDER_UNDERLINE}></div>
      <AddButton
        onClick={() => {
          navigate("/Blog/New");
        }}
        title="Blog"
      />

      {/* {blogs?.map((row , index)=>{
    return <Card
    key={index}
    // name ={}
    />
  })} */}

      <BlogCard blogs={blogs} deleteBlog={deleteBlog} editBlogs={editBlogs} />
    </ContainerContent>
  );
};
export default BlogMain;
