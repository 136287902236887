import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContainerContent from "../../components/layouts/ContainerContent";
import api from "../../api";
import { socialAll } from "../../utils/constant";
import {
  ACTIVE_STYLE,
  INACTIVE_STYLE,
  STYLE_BORDER_UNDERLINE,
  STYLE_HEAD,
} from "../../lib/style";
import useSweetAlert from "../../utils/useSweetAlert";
import PortfolioSection from "./render/PortfolioSection";
import { AddButton } from "../../components/attributes/Button";

const PortfolioMain = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [portfolioType, setPortfolioType] = useState("twitter");
  const { successDeleteAlert } = useSweetAlert();

  useEffect(() => {
    LoadData();
  }, [portfolioType]);

  const LoadData = async () => {
    try {
      setLoading(true);
      let query = "";
      if (portfolioType) {
        query = `?type=${portfolioType}&sort=index:1`;
        // query = `?type=${portfolioType}`;
      }
      const { data } = await api.get(`/portfolios${query}`, {});
      data.portfolios = data?.portfolios?.map((item,index)=>{
        if(item?.index){
          return item
        }
        return{...item,index}
      })

      console.log("data",data)
      setData(data);
    } catch (error) {
      console.error("ERROR TO LOAD PORTFOLIO : ", error);
    } finally {
      setLoading(false);
    }
  };

  const onClickToDeleteCard = async (id) => {
    try {
      setLoading(true);
      await api.delete(`/portfolios/${id}`);
      navigate("/Portfolio");
      successDeleteAlert();
      LoadData();
    } catch (error) {
      console.error("ERROR TO DELETE PORTFOLIO : ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContainerContent>
      <div className={STYLE_HEAD}>Portfolio</div>
      <div className={STYLE_BORDER_UNDERLINE}></div>
      <AddButton
        onClick={() => {
          navigate("/Portfolio/New");
        }}
        title="Portfolio"
      />
      <div className="grid grid-cols-8 justify-items-center">
        {socialAll?.map((row) => {
          const isActive = row?.value === portfolioType;
          return (
            <div
              onClick={() => setPortfolioType(row?.value)}
              className="w-full h-full flex justify-center"
              key={row?._id}
            >
              <button>
                <div className={isActive ? ACTIVE_STYLE : INACTIVE_STYLE}>
                  <div>{row?.title} </div>
                </div>
              </button>
            </div>
          );
        })}
      </div>
      <PortfolioSection
        data={data}
        onClickToDeleteCard={onClickToDeleteCard}
        portfolioType={portfolioType}
      />
    </ContainerContent>
  );
};
export default PortfolioMain;
