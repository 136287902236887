export const socialAll = [
  { title: "Twitter", value: "twitter" },
  {
    title: "Facebook",
    value: "facebook",
  },
  { title: "User Review", value: "user_review" },
  {
    title: "Instagram",
    value: "instagram",
  },
  { title: "Youtube", value: "youtube" },
  { title: "Tiktok", value: "tiktok" },
  { title: "Event", value: "event" },
  { title: "Banner", value: "banner" },
];

export const valueSocial = [
  { value: "twitter", label: "Twitter" },
  { value: "facebook", label: "Facebook" },
  { value: "user_review", label: "User Review" },
  { value: "instagram", label: "Instagram" },
  { value: "youtube", label: "Youtube" },
  { value: "tiktok", label: "Tiktok" },
  { value: "event", label: "Event" },
  { value: "banner", label: "Banner" },
];

export const valueFacebook = [
  { value: "mini_review", label: "Mini review" },
  { value: "buzz_shout_out", label: "Buzz shout out" },
];

export const reNameSocial = {
  facebook: "Facebook",
  instagram: "Instagram",
  twitter: "Twitter",
  blog: "Blog",
  tiktok: "Tiktok",
  youtube: "Youtube",
  event: "Event",
  banner: "Banner",
};

export const reNameYoutube = {
  mini_review: "VDO ON FACEBOOK",
  buzz_shout_out: "VDO ON YOUTUBE",
};
