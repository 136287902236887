import { FunctionGetYouTubeId } from "radium-omnibus";
import React from "react";
import Iframe from "react-iframe";
import { useNavigate } from "react-router-dom";
import { DeleteButton } from "../../../components/attributes/Button";
import { ICON_DELETE } from "../../../lib/icons";
import { STYLE_CARDS_PORTFOLIO } from "../../../lib/style";
import { FunctionGetPathUrl } from "../../../utils/useFunctions";

// หน้ารวมทุกการ์ด

const YoutubeRender = ({ portfolio, index, clickDeleteCard }) => {
  const navigate = useNavigate();
  return (
    <div className="">
      {/* youtube (vdo on facebook)  */}
      {portfolio?.radio === "mini_review" && (
        <div key={index} className="h-56 object-cover ">
          <img
            className="h-56 w-full  object-cover "
            src={FunctionGetPathUrl(portfolio?.image_key)}
          />
        </div>
      )}

      {/* youtube (vdo ON YOUTUBE) */}
      <div className="">
        {portfolio?.radio === "buzz_shout_out" && (
          <div className=" h-56 object-cover ">
            <Iframe
              url={
                portfolio?.link?.slice(0, 5) === "https"
                  ? `https://www.youtube.com/embed/${FunctionGetYouTubeId(
                      portfolio?.link
                    )}`
                  : `https://www.youtube.com/embed/${portfolio?.link}`
              }
              width="100%"
              height="100%"
              display="initial"
              position="relative"
            />
            <div className="flex-1">
              <div className=" text-xl  pt-2 px-3 flex my-3">
                {portfolio?.title}
              </div>
              <div className="  font-light pt-1 line-clamp-2  px-3 ">
                {portfolio?.description}
              </div>
            </div>
          </div>
        )}
        <DeleteButton onClick={(e) => clickDeleteCard(e, portfolio?._id)} />
      </div>
    </div>
  );
};

export default YoutubeRender;
