import { useState } from "react";
import ContainerContent from "../../components/layouts/ContainerContent";
import { ACTIVE_STYLE, INACTIVE_STYLE } from "../../lib/style";
import FormMarketer from "./FormMarketer";
import FormReviewer from "./FormReviewer";

const ContactFormMain = () => {
  const [checkContactType, setCheckContactType] = useState("marketer");

  const isActive = (row) => {
    if (row.value === checkContactType) {
      return ACTIVE_STYLE;
    }
    return INACTIVE_STYLE;
  };

  return (
    <ContainerContent>
      <div className="flex mx-auto justify-center gap-20 py-5 ">
        <div
          onClick={() => {
            setCheckContactType("marketer");
          }}
          className={isActive({ value: "marketer" })}
          // <div className={isActive ? ACTIVE_STYLE : INACTIVE_STYLE}>
        >
          <div className="">Tab Marketer</div>
        </div>

        <div
          onClick={() => {
            setCheckContactType("reviewer");
          }}
          className={isActive({ value: "reviewer" })}
        >
          <div className="">Tab Reviewer</div>
        </div>
      </div>
      {checkContactType === "marketer" ? <FormMarketer /> : <FormReviewer />}
    </ContainerContent>
  );
};
export default ContactFormMain;
