import Swal from "sweetalert2";
import { toast } from "react-toastify";

const useSweetAlert = () => {
  const confirm = async (
    options = {
      title: "ยืนยันการบันทึกใช่หรือไม่ ?",
      text: "",
      callback: () => {},
      other: {},
    }
  ) => {
    try {
      const resultSwalAlert = await Swal.fire({
        title: options.title,
        showCancelButton: true,
        confirmButtonText: `ยืนยัน`,
        cancelButtonText: "ยกเลิก",
        showLoaderOnConfirm: true,
        showLoaderOnDeny: false,
        preConfirm: async () => {
          try {
            return options.callback();
          } catch (error) {
            console.error("Error", error);
          }
        },
        ...options.other,
      });
      return resultSwalAlert;
    } catch (error) {
      console.error("Er", error);
    }
  };

  const warning = async (
    options = { title: "WARNING", text: "", other: {} }
  ) => {
    try {
      return await Swal.fire({
        title: options.title,
        text: options.text,
        icon: "warning",
        confirmButtonText: "ยืนยัน",
        ...options.other,
      });
    } catch (error) {}
  };

  const successDeleteAlert = () => {
    toast.success("ลบข้อมูลสำเร็จ", {
      position: "top-center",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return { confirm, warning, successDeleteAlert };
};

export default useSweetAlert;
